/* ============================================================

 Theme Name: Voicer
 Theme URI: http://
 Description: Voicer Multipage Template
 Author: Websmirno
 Author URI: https://themeforest.net/user/websmirno
 Version: 1.0

============================================================== */
/* ==============================================================

 ====>> Table Of Content

	* Vars
	* Fonts
	* padding/margin
	* BASE STYLE

	* ===== Components ===== *
		* first-letter
		* table
		* icon
		* bread-crumbs
		* logo
		* hamburger
		* button
		* keyframes
		* slick-arrow
		* slick-dots
		* video btn
		* v-separator
		* section-header
		* popup-wrap
		* read-more
		* visible
		* tab-links
		* menu-gradient
		* calendar
		* tag-list
		* noUi
		* left-sidebar
		* to-top

	* ===== Form elements ===== *
		* form-popup
		* input
		* book-form
		* form-control
		* select2
		* datetimepicker
		* search-form
		* comment-form
		* contact-form

	* ===== Widgets ===== *
		* page-name
		* contact-list
		* social-list
		* main-slider
		* Popup Player
		* Playlist Player
		* text-block
		* parralax-section
		* banner-booking
		* studio-slider
		* project-card
		* project-slider
		* news-card
		* news-slider
		* cd-headline
		* engineer-card
		* testimonials-slider
		* instagram-grid
		* book-btn
		* icon-block
		* call-banner
		* tab-blocks
		* tab-slider
		* history-list
		* team-card
		* tile-price-wrap
		* book-list
		* skill-list
		* tile-list
		* clients-slider
		* client-filter
		* tt-pagination
		* review-block
		* prices-card
		* gallery-grid
		* massonry-grid
		* product-card
		* blog-post
		* post-teaser
		* sidebar
		* recent-post
		* post-author
		* comment-list
		* porduct-list-top
		* popular-product-list
		* product-slider-wrap
		* product-description
		* cart-table
		* map-block
		* contact-bnr
		* project-ico-list
		* awp-project-player
		* player

		* ==== Footer ==== *
			* footer
			* footer-nav
		
		* ==== Header ==== *
			* header
			* main-nav

	* ===== Layouts ===== *
		* home-page layout
		


============================================================== */